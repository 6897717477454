<template>
  <v-app class="login-bg">
    <div class="container-fluid auth-pos pa-10">
      <div class="row fill-height">
        <div class="col-lg-10 col-12 mx-auto my-auto">
          <div class="row">
            <div class="col-lg-12 text-center">
              <img
                src="/assets/images/brand/white-horizontal-logo.svg"
                width="240"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto my-auto auth-glass rounded-lg pa-10">
              <div class="row no-gutters">
                <div
                  class="col-lg-12 d-flex justify-center justify-lg-end align-center"
                >
                  <v-btn
                    color="success"
                    class="rounded-pill me-2"
                    :title="$t('support')"
                    href="https://wa.me/+573195675844"
                    elevation="0"
                    outlined
                  >
                    <v-icon>mdi-whatsapp</v-icon>
                    <span>{{ $t("support") }}</span>
                  </v-btn>
                  <v-switch
                    prepend-icon="mdi-keyboard"
                    v-model="keyboardMode"
                    inset
                  ></v-switch>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mx-auto pa-lg-4 pa-2 rounded-lg">
                  <div class="row welcome-message mb-3">
                    <div class="col-lg-12 text-center">
                      <h1 class="success--text">Nubempresa POS</h1>
                      <p class="grey--text">
                        Por favor, inicia sesión con tu dirección de correo
                        electrónico y contraseña.
                      </p>
                    </div>
                  </div>
                  <div class="row mb-0" v-if="responseError">
                    <div class="col-12 pb-0">
                      <v-alert type="error" color="error">{{
                        $t("Credenciales inválidas")
                      }}</v-alert>
                    </div>
                  </div>
                  <ValidationObserver v-slot="{ invalid }" ref="form">
                    <form class="v-form">
                      <div v-if="keyboardMode">
                        <ng-text-keyboard
                          autocomplete="new-password"
                          persistent-placeholder
                          v-model="form.email"
                          :filled="false"
                          :dense="false"
                          class="mt-4"
                          :label="$t('email')"
                          :placeholder="$t('email')"
                          rules="required|email|min:8"
                          show-required="true"
                          :activeKeyboard="keyboardMode"
                        />

                        <ng-password-keyboard
                          persistent-placeholder
                          v-model="form.password"
                          counter
                          :filled="false"
                          :dense="false"
                          :label="$t('password')"
                          :placeholder="$t('password')"
                          rules="required|min:8"
                          show-required="true"
                          v-on:keyup.enter="loginWithPasswd"
                          autocomplete="new-password"
                          :activeKeyboard="keyboardMode"
                        />
                      </div>

                      <div v-else>
                        <ng-text
                          autocomplete="new-password"
                          persistent-placeholder
                          v-model="form.email"
                          :filled="false"
                          :dense="false"
                          class="mt-4"
                          :label="$t('email')"
                          :placeholder="$t('email')"
                          rules="required|email|min:8"
                          show-required="true"
                        />
                        <ng-passwd
                          persistent-placeholder
                          v-model="form.password"
                          counter
                          :filled="false"
                          :dense="false"
                          :label="$t('password')"
                          :placeholder="$t('password')"
                          rules="required|min:8"
                          show-required="true"
                          v-on:keyup.enter="loginWithPasswd"
                          autocomplete="new-password"
                        />
                      </div>

                      <div class="text-lg-center text-right">
                        <div class="mx-auto mb-5">
                          <router-link
                            to="/auth/recover"
                            class="link success--text"
                          >
                            {{ $t("forgot_password") }}
                          </router-link>
                        </div>
                      </div>
                      <v-btn
                        elevation="0"
                        block
                        color="success"
                        :disabled="invalid"
                        @click="loginWithPasswd()"
                        class="mt-2"
                      >
                        {{ $t("log_in") }}
                      </v-btn>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<style lang="scss">
html,
body {
  overflow-y: hidden;
}

@media only screen and (max-width: 1366px) {
  html,
  body {
    overflow-y: visible;
  }
}

.link {
  text-decoration: none;
}

.login-bg {
  height: 100% !important;
  margin-bottom: -5px;
  background-color: #f7f7f9 !important;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.52),
      rgba(0, 0, 0, 0.75)
    ),
    url("/assets/background-3-min.jpg") !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
</style>

<script>
//import * as firebase from 'firebase';
import appUtil from "@/plugins/app-utils.js";
export default {
  name: "Login",
  data: () => ({
    keyboardMode: false,
    form: { email: "", password: "" },
    social: [
      /*  {
          color: '#55ACEE',
          icon: 'mdi-twitter',
          type: 'twitter',
          text: 'Ingresar con Twitter',
        },*/
      {
        color: "#3B5998",
        icon: "mdi-facebook",
        type: "facebook",
        text: "Ingresar con Facebook",
      },
      {
        color: "#DD4b39",
        icon: "mdi-google",
        type: "google",
        text: "Ingresar con Google",
      },
    ],
    responseError: false,
  }),
  created() {
    const configFormMpn = {
      user: {
        name: "user",
        label: "Usuario",
        validate: ["required"],
        "prepend-inner-icon": "mdi-card-account-details",
        required: true,
        dense: false,
      },
      passwd: {
        name: "passwd",
        label: "Contraseña",
        validate: ["required"],
        "prepend-inner-icon": "mdi-lock-question",
        required: true,
        dense: false,
      },
    };
    //const form = new NgForm(configFormMpn, this)
    //this.form = new Proxy(form, handler)

    //this.form.init();
  },
  mounted() {
    this.$auth.loadInfoSession().then((response) => {
      if (response.success) {
        this.redirectUrl();
      }
    });
  },

  methods: {
    redirectUrl() {
      appUtil.redirectUrl(this.$router);
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
    async loginWithPasswd() {
      //this.$store.commit('setLoading', true);
      let response = await this.$auth.authenticated(this.form);
      if (response.success) {
        this.redirectUrl();
      } else {
        this.responseError = true;
      }
    },
    login(type) {
      switch (type) {
        case "google":
          this.signUserInGoogleAuth();
          break;
        case "facebook":
          this.signUserInFacebookAuth();
          break;
        case "twitter":
          this.signUserInTwitterAuth();
          break;
      }
    },

    loginServer(user) {
      if (user) {
        //this.$store.commit('setLoading', true);
        this.$auth.authenticatedUserFirebase(user, this).then((res) => {
          //this.$store.commit('setLoading', false);
          if (res.success) {
            this.$store.commit("setAuth", res.data);
            this.redirectUrl();
          } else {
            this.responseError = true;
          }
        });
      }
    },
    signUserInGoogleAuth() {
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then((response) => {
          const newUser = {
            uid: response.user.uid,
            name: response.user.displayName,
            email: response.user.email,
            type: "google",
            photoUrl: response.user.photoURL,
          };
          this.loginServer(newUser);
        })
        .catch((error) => {});
    },
    signUserInFacebookAuth() {
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then((response) => {
          const newUser = {
            uid: response.user.uid,
            name: response.user.displayName,
            email: response.user.email,
            type: "facebook",
            photoUrl: response.user.photoURL,
          };
          this.loginServer(newUser);
        })
        .catch((error) => {});
    },
    signUserInGithubAuth() {
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.GithubAuthProvider())
        .then((response) => {
          //commit('setLoading', false)
          const newUser = {
            uid: response.user.uid,
            name: response.user.displayName,
            email: response.user.email,
            type: "github",
            photoUrl: response.user.photoURL,
          };
          this.loginServer(newUser);
        })
        .catch((error) => {});
    },
    signUserInTwitterAuth() {
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.TwitterAuthProvider())
        .then((response) => {
          const newUser = {
            uid: response.user.uid,
            name: response.user.displayName,
            email: response.user.email,
            type: "twitter",
            photoUrl: response.user.photoURL,
          };
          this.loginServer(newUser);
        })
        .catch((error) => {});
    },
    signUserInAuth({ commit }, payload) {
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then((response) => {
          const newUser = {
            uid: response.user.uid,
            name: response.user.displayName,
            email: response.user.email,
            photoUrl: response.user.photoURL,
          };
          this.loginServer(newUser);
        })
        .catch((error) => {});
    },
    signUserUpAuth(payload) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then((user) => {
          // commit('setLoading', false)
          const newUser = {
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            photoUrl: user.photoURL,
          };
          this.loginServer(newUser);
        })
        .catch((error) => {});
    },
  },
};
</script>
